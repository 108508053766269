.img_home_info {
  max-width: 400px;
  max-height: 200px;
}

.card_home_info {
  max-width: 400px;
  height: 400px;
  margin: auto;
}

.contacto-page {
  max-width: 600px;
}

.contacto-page input {
  margin: 10px 5px 0px 5px;
}

.contacto-page input:first-of-type {
  margin: 0px 5px 0px 5px;
}

.contacto-page input:last-of-type {
  margin: 10px 5px 0px 5px;
}

.card-icon {
  border-radius: 50%;
  border: 1px solid grey;
  width: 80px;
  height: 80px;
  justify-content: center;
  text-align: center;
}

.quote-box {
    box-shadow: #214853 0px 5px 15px;
}

.quote {
  margin-top: 10px;

  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  font-size: 1.1rem;
}

.quote-name {
  font-weight: bolder;
}

.quote-job {
  font-weight: bold;
}
